import React from 'react'
import {
  Resource,
  List,
  Datagrid,
  Show,
  Edit,
  Create,
  Filter,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
  BooleanField,
  NumberField,
  TextInput,
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  RadioButtonGroupInput,
  EditButton,
  DeleteButton,
  CloneButton
} from 'react-admin'
import { compose, withProps } from 'recompose';
import get from 'lodash/get'

const LABELS = {
  startDate: 'Start Date',
  duration: 'Length of Event (in hours)',
  setupHours: 'Setup Duration (in hours)',
  teardownHours: 'Teardown Duration (in hours)',
  description: 'Description',
  hideEvent: 'Hide Event from Public Calendar?',
  hideName: 'Hide Name on Public Calendar?',
  hideDescription: 'Hide Description on Public Calendar?',
  color: 'What color do you want the event to be on the public calendar?'
}

const EventFilters = props => (
  <Filter {...props}>
    <ReferenceInput reference="organizations" source="organization">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const EventTitle = ({record}) => (<span>Event {record && `${record.name} at ${record.details ? record.details.starts_at.toLocaleString() : 'Unknown'}`}</span>)

const ColorDisplay = ({record}) => {
  if (record.id === '0') {
    return (<span>Use Default Color</span>)
  }
  return (<span style={{padding: 5, backgroundColor: record.backgroundColor}}>Color {record.id}</span>)
}

// IDs 1 and 2 are reserved for the internal calendar
const COLORS = [
  {id: '0', backgroundColor: 'none'},
  {id: '3', backgroundColor: '#8e24aa'},
  {id: '4', backgroundColor: '#e67c73'},
  {id: '5', backgroundColor: '#f6c026'},
  {id: '6', backgroundColor: '#f5511d'},
  {id: '7', backgroundColor: '#039be5'},
  {id: '8', backgroundColor: '#616161'},
  {id: '9', backgroundColor: '#3f51b5'},
  {id: '10', backgroundColor: '#0b8043'},
  {id: '11', backgroundColor: '#d60000'},
]

const EventList = props => (
  <List {...props} filters={<EventFilters />}>
    <Datagrid>
      <ReferenceField source="organization" reference="organizations" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <DateField source="details.starts_at" showTime label={LABELS.startDate} />
      <BooleanField source="details.hide_event" label={LABELS.hideEvent} />
      <EditButton />
      <CloneButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

const EventCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput source="organization" reference="organizations">
        <AutocompleteInput />
      </ReferenceInput>
      <TextInput source="name" />
      <DateTimeInput source="details.starts_at" label={LABELS.startDate} inputProps={{step: 15 * 60}} />
      <NumberInput source="details.duration" label={LABELS.duration} step={0.25} />
      <NumberInput source="details.setup_hours" label={LABELS.setupHours} step={0.25} />
      <NumberInput source="details.teardown_hours" label={LABELS.teardownHours} step={0.25} />
      <TextInput source="details.description" label={LABELS.description} multiline />
      <BooleanInput source="details.hide_event" label={LABELS.hideEvent} />
      <BooleanInput source="details.hide_name" label={LABELS.hideName} />
      <BooleanInput source="details.hide_description" label={LABELS.hideDescription} />
      <RadioButtonGroupInput source="details.color" label={LABELS.color} choices={COLORS} optionText={<ColorDisplay />} />
    </SimpleForm>
  </Create>
)

const EventEdit = props => (
  <Edit {...props} title={<EventTitle />}>
    <SimpleForm>
      <ReferenceInput source="organization" reference="organizations">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <DateTimeInput source="details.starts_at" label={LABELS.startDate} inputProps={{step: 15 * 60}} />
      <NumberInput source="details.duration" label={LABELS.duration} step={0.25} />
      <NumberInput source="details.setup_hours" label={LABELS.setupHours} step={0.25} />
      <NumberInput source="details.teardown_hours" label={LABELS.teardownHours} step={0.25} />
      <TextInput source="details.description" label={LABELS.description} multiline />
      <BooleanInput source="details.hide_event" label={LABELS.hideEvent} />
      <BooleanInput source="details.hide_name" label={LABELS.hideName} />
      <BooleanInput source="details.hide_description" label={LABELS.hideDescription} />
      <RadioButtonGroupInput source="details.color" label={LABELS.color} choices={COLORS} optionText={<ColorDisplay />} />
    </SimpleForm>
  </Edit>
)

const ColorField = ({source, record = {}}) => {
  const selColor = COLORS.find(elem => elem.id === get(record, source)) || COLORS[0]
  return (<ColorDisplay record={selColor} />)
}
const EventShow = props => (
  <Show {...props} title={<EventTitle />}>
    <SimpleShowLayout>
      <ReferenceField source="organization" reference="organizations" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <DateField source="details.starts_at" showTime label={LABELS.startDate} />
      <NumberField source="details.duration" label={LABELS.duration} />
      <NumberField source="details.setup_hours" label={LABELS.setupHours} />
      <NumberField source="details.teardown_hours" label={LABELS.teardownHours} />
      <TextField source="details.description" label={LABELS.description} />
      <BooleanField source="details.hide_event" label={LABELS.hideEvent} />
      <BooleanField source="details.hide_name" label={LABELS.hideName} />
      <BooleanField source="details.hide_description" label={LABELS.hideDescription} />
      <ColorField source="details.color" label={LABELS.color} />
    </SimpleShowLayout>
  </Show>
)

const setEventForms = withProps({
  list: EventList,
  create: EventCreate,
  edit: EventEdit,
  show: EventShow
})

export const EventResource = compose(setEventForms)(Resource)
import React, { useState, useEffect } from 'react'
import { Admin } from 'react-admin'
import { createHashHistory as createHistory } from 'history'

import { CustomLoginPage } from './firebaseElems/Components'
import { authProvider, dataProvider } from './firebaseElems/core'

import { EventResource } from './resources/Event'
import { OrganizationResource } from './resources/Organization'

const showForEligibleUser = permissions => {
  if (!permissions || !permissions.user_id) { return null }
  if (!permissions.admin) { return null }

  return [
    <EventResource name="events" />,
    <OrganizationResource name="organizations" />
  ]
}

function App() {
  const [history, setHistory] = useState(null)
  useEffect(() => {
    setHistory(createHistory())
  }, [])

  if (!history) {
    return null
  }

  return (
    <Admin
      loginPage={CustomLoginPage}
      dataProvider={dataProvider}
      authProvider={authProvider}
      history={history}
    >
      {showForEligibleUser}
    </Admin>
  );
}

export default App;

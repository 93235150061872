import firebase from 'firebase'
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from 'react-admin-firebase';

import { FIREBASE_CONFIG } from '../utils/Constants'

console.log('Config', FIREBASE_CONFIG)

const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG)

export const authProvider = FirebaseAuthProvider(FIREBASE_CONFIG)
export const dataProvider = FirebaseDataProvider(
  FIREBASE_CONFIG,
  {
    logging: true,
    app: firebaseApp,
    persistence: 'session'
  }
)
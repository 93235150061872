import React from 'react'
import {
  Resource,
  List,
  Datagrid,
  Show,
  Edit,
  Create,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceManyField,
  TextInput,
  DateTimeInput,
  ShowButton,
  EditButton,
  DeleteButton,
} from 'react-admin'
import { compose, withProps } from 'recompose';

const LABELS = {
  primaryContact: 'Primary Contact',
  primaryEmail: 'E-Mail'
}

const OrganizationList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="details.primary_contact" label={LABELS.primaryContact} />
      <TextField source="details.primary_email" label={LABELS.primaryEmail} />
      <ShowButton />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

const OrganizationCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <TextInput source="details.primary_contact" label={LABELS.primaryContact} />
      <TextInput source="details.primary_email" label={LABELS.primaryEmail} />
    </SimpleForm>
  </Create>
)


const OrganizationEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="details.primary_contact" label={LABELS.primaryContact} />
      <TextInput source="details.primary_email" label={LABELS.primaryEmail} />
    </SimpleForm>
  </Edit>
)

const OrganizationShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="details.primary_contact" label={LABELS.primaryContact} />
      <TextField source="details.primary_email" label={LABELS.primaryEmail} />
      <ReferenceManyField reference="events" target="organization" label="Events">
        <Datagrid>
          <TextField source="name" />
          <DateField source="starts_at" showTime />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)

const setOrganizationForms = withProps({
  list: OrganizationList,
  create: OrganizationCreate,
  edit: OrganizationEdit,
  show: OrganizationShow
})

export const OrganizationResource = compose(setOrganizationForms)(Resource)